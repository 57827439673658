import React, { useState, useEffect, useCallback, useContext } from "react"
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import Login from '../components/Login'
import CustomerAccount from '../components/CustomerAccount'
import UserContext from '../components/context/UserContext'
import { Modal, Loading, Button, Spacer } from '@geist-ui/react'

import { magic } from "../lib/magic"


const Account = () => {

  const [loading, setLoading] = useState()
  const [past, setPast] = useState()
  const [fulfilledorders, setFulfilledOrders] = useState([])
  const [missedorders, setMissedOrders] = useState([])
  const [pastloading, setPastLoading] = useState()
  const [tab, setTab] = useState(1)
  const [charges, setCharges] = useState([])
  const [chargesloading, setChargesLoading] = useState()
  const [subscriptions, setSubscriptions] = useState([])
  const [update, setUpdate] = useState()
  const [bagels, setBagels] = useState([])
  const [creamcheeses, setCreamcheeses] = useState([])

  const { currentuser, setCurrentUser, loggedin, setLoggedIn, setAdmin, upcomingorders, setUpcomingOrders } = useContext(UserContext)

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await magic.user.logout();
      setCurrentUser([]);
      setUpcomingOrders([]);
      setLoading();
      setLoggedIn(false);
      setAdmin(false);
      navigate('/');
    } catch {
      setLoading();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Account - Saturday Bagel Club</title>
      </Helmet>
      {!loggedin && <Login page='account' onChange={() => navigate('/')} />}
      <div className="account">
        {loading && <Loading />}
        {currentuser.length > 0 && !loading && <>
          <h4>{currentuser[0][0]}</h4>
          <p>{currentuser[0][3]}</p>
          <Button scale={0.25} type="secondary" onClick={logout} shadow>Log Out</Button>
          <Spacer h={1.5} />
          <CustomerAccount customer={currentuser[0]} upcomingorders={upcomingorders} />
         </>}
      </div>
    </>
  )
}

export default Account
